(function($) {
    $('.bis_notification').live("click", function(e) {
        e.preventDefault();
        var skuBaseId = $(this).attr('data-sku-base-id');
        var file = '/waitlist/signup.tmpl?SKU_BASE_ID='+skuBaseId+'&REQUEST_TYPE=BIS';
        var options = {
            includeBackground: false,
            includeCloseLink: true,
            hideOnClickOutside: true,
            cssStyle: {border:'1px solid #5dba98', width: 'auto', height: 'auto', padding: '0'}
        };
        options.content = '<iframe style="padding:33px 20px 0"  width="343px" height="220px" scrolling="no" frameborder="0" src="'+file+'"></iframe>';
        generic.overlay.launch(options);
    });
})(jQuery);


/* handle SPP/MPP waitlist */
(function($) {

  var waitlist = function(contextNode) {
    var invContainer = $('.product__inventory-status', contextNode);

    if(invContainer.length) {
      var wlTrigger = $('[data-wl-trigger]', invContainer);
      var bisNotificationHeight = Drupal.settings.bis_notification_height;
      var wlOverlay = function(skuBaseId, requestType) {
        var requestType = requestType || 'BIS';
        var options = {
          href: '/waitlist/signup.tmpl?SKU_BASE_ID=' + skuBaseId + '&REQUEST_TYPE=' + requestType,
          iframe: true,
          includeBackground: false,
          includeCloseLink: true,
          hideOnClickOutside: true,
          cssStyle: {
            width: '500px',
            height: bisNotificationHeight || '470px'
          }
        };
        generic.overlay.launch(options);
      }

      wlTrigger.on('click.wl', function(e) {
        wlOverlay($(this).attr('data-sku-base-id'), $(this).attr('data-wl-trigger'))
        e.preventDefault();
      });

      invContainer.on('sku:select', function(e, skuData) {
        wlTrigger.attr('data-sku-base-id', skuData.SKU_BASE_ID);
        e.stopPropagation();
      });
    }
  };

  $(document).on('MPP:productQV:rendered', function(e, QScontainer) {
    waitlist(QScontainer);
  });

  $(function() {
    waitlist($(this));
  });

})(jQuery);
